@include govuk-exports('govuk/component/table') {
  .govuk-table {
    @include govuk-font($size: 19);
    @include govuk-text-colour;
    width: 100%;
    @include govuk-responsive-margin(6, 'bottom');

    border-spacing: 0;
    border-collapse: collapse;
  }

  @if $govuk-new-typography-scale {
    // Modifier for tables with a lot of data. Tables with lots of data benefit
    // from a smaller font size on small screens.
    .govuk-table--small-text-until-tablet {
      @include govuk-media-query($until: tablet) {
        @include govuk-font-size($size: 16);
      }
    }
  }

  .govuk-table__header {
    @include govuk-typography-weight-bold;
  }

  .govuk-table__header,
  .govuk-table__cell {
    padding: govuk-spacing(2) govuk-spacing(4) govuk-spacing(2) 0;
    border-bottom: 1px solid $govuk-border-colour;
    text-align: left;
    vertical-align: top;
  }

  .govuk-table__cell--numeric {
    @include govuk-font-tabular-numbers;
  }

  .govuk-table__header--numeric,
  .govuk-table__cell--numeric {
    text-align: right;
  }

  .govuk-table__header:last-child,
  .govuk-table__cell:last-child {
    padding-right: 0;
  }

  .govuk-table__caption {
    @include govuk-typography-weight-bold;

    display: table-caption;
    text-align: left;
  }

  // Modifiers that make captions look more like their equivalent headings
  .govuk-table__caption--xl,
  .govuk-table__caption--l,
  .govuk-table__caption--m {
    margin-bottom: govuk-spacing(3);
  }

  .govuk-table__caption--xl {
    @include govuk-font-size($size: 48);
  }

  .govuk-table__caption--l {
    @include govuk-font-size($size: 36);
  }

  .govuk-table__caption--m {
    @include govuk-font-size($size: 24);
  }
}

/*# sourceMappingURL=_index.scss.map */
