/**
Helper classes that were not covered through govuk-components
 */

.govuk-phase-banner__text {
  width: 100%;
}

.pre-wrap {
  white-space: pre-wrap;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
  margin-left: -(govuk-spacing(3));
  margin-right: -(govuk-spacing(3));
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @include govuk-media-query($from: tablet) {
    flex-direction: row;
    gap: govuk-spacing(1);
  }
}

.align-items-end {
  align-items: flex-end;
}

.col-flex-three-quarters {
  box-sizing: border-box;
  flex: 0 0 auto;
  width: govuk-grid-width('three-quarters');
  padding: 0 govuk-spacing(3);
}

.col-flex-one-quarter {
  box-sizing: border-box;
  flex: 0 0 auto;
  width: govuk-grid-width('one-quarter');
  padding: 0 govuk-spacing(3) 0 0;
}

a[role='button'] {
  @extend %govuk-link;
  color: $govuk-link-visited-colour;
  cursor: pointer;
}

.highlight-error {
  color: $govuk-error-colour;
  font-weight: bold;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: end;
}
