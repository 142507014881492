////
/// @group overrides
////

// stylelint-disable declaration-no-important

/// Directions for spacing
///
/// @type Map
/// @access private

$_spacing-directions: ('top', 'right', 'bottom', 'left') !default;

/// Generate responsive spacing override classes
///
/// Generate spacing override classes for the given property (e.g. margin)
/// for each point in the responsive spacing scale.
///
/// @param {String} $property - Property to add spacing to (e.g. 'margin')
///
/// @example css
///  .govuk-\!-margin-4 {
///    margin: 15px !important;
///  }
///
///  @media (min-width: 40.0625em) {
///    .govuk-\!-margin-4 {
///      margin: 20px !important;
///    }
///  }
///
/// @access private

@mixin _govuk-generate-responsive-spacing-overrides($property) {
  // For each point in the spacing scale (defined in settings), create an
  // override that affects all directions...
  @each $scale-point, $scale-map in $govuk-spacing-responsive-scale {
    .govuk-\!-#{$property}-#{$scale-point} {
      @include _govuk-responsive-spacing($scale-point, $property, 'all', true);
    }

    // ... and then an override for each individual direction
    @each $direction in $_spacing-directions {
      .govuk-\!-#{$property}-#{$direction}-#{$scale-point} {
        @include _govuk-responsive-spacing($scale-point, $property, $direction, true);
      }
    }
  }
}

/// Generate static spacing override classes
///
/// Generate spacing override classes for the given property (e.g. margin)
/// for each point in the non-responsive spacing scale.
///
/// @param {String} $property - Property to add spacing to (e.g. 'margin')
///
/// @example css
///   .govuk-\!-static-margin-4 {
///      margin: 20px !important;
///   }
///
/// @access private
@mixin _govuk-generate-static-spacing-overrides($property) {
  @each $spacing-point in map-keys($govuk-spacing-points) {
    .govuk-\!-static-#{$property}-#{$spacing-point} {
      #{$property}: govuk-spacing($spacing-point) !important;
    }

    @each $direction in $_spacing-directions {
      .govuk-\!-static-#{$property}-#{$direction}-#{$spacing-point} {
        #{$property}-#{$direction}: govuk-spacing($spacing-point) !important;
      }
    }
  }
}

@include govuk-exports('govuk/overrides/spacing') {
  @include _govuk-generate-responsive-spacing-overrides('margin');
  @include _govuk-generate-responsive-spacing-overrides('padding');

  @include _govuk-generate-static-spacing-overrides('margin');
  @include _govuk-generate-static-spacing-overrides('padding');
}

/*# sourceMappingURL=_spacing.scss.map */
