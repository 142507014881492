@include govuk-exports('govuk/component/inset-text') {
  .govuk-inset-text {
    @include govuk-font($size: 19);
    @include govuk-text-colour;
    padding: govuk-spacing(3);
    // Margin top intended to collapse
    // This adds an additional 10px to the paragraph above
    @include govuk-responsive-margin(6, 'top');
    @include govuk-responsive-margin(6, 'bottom');

    clear: both;

    border-left: $govuk-border-width-wide solid $govuk-border-colour;

    > :first-child {
      margin-top: 0;
    }

    > :only-child,
    > :last-child {
      margin-bottom: 0;
    }
  }
}

/*# sourceMappingURL=_index.scss.map */
