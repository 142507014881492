@include govuk-exports('govuk/component/tabs') {
  .govuk-tabs {
    @include govuk-responsive-margin(1, 'top');
    @include govuk-responsive-margin(6, 'bottom');
    @include govuk-font($size: 19);
  }

  .govuk-tabs__title {
    // Set the size and weight again because this element is a heading and the
    // user agent font size overrides the inherited font size
    @include govuk-font-size($size: 19);
    @include govuk-typography-weight-regular;
    @include govuk-text-colour;
    margin-bottom: govuk-spacing(2);
  }

  .govuk-tabs__list {
    margin: 0;
    padding: 0;
    list-style: none;
    @include govuk-responsive-margin(6, 'bottom');
  }

  .govuk-tabs__list-item {
    margin-left: govuk-spacing(5);

    &::before {
      @include govuk-text-colour;
      content: '\2014 '; // "— "
      margin-left: govuk-spacing(-5);
      padding-right: govuk-spacing(1);
    }
  }

  .govuk-tabs__tab {
    @include govuk-link-common;
    @include govuk-link-style-default;

    display: inline-block;
    margin-bottom: govuk-spacing(2);
  }

  .govuk-tabs__panel {
    @include govuk-responsive-margin(8, 'bottom');
  }

  // GOV.UK Frontend JavaScript enabled
  .govuk-frontend-supported {
    @include govuk-media-query($from: tablet) {
      .govuk-tabs__list {
        @include govuk-clearfix;
        margin-bottom: 0;
        border-bottom: 1px solid $govuk-border-colour;
      }

      .govuk-tabs__title {
        display: none;
      }

      .govuk-tabs__list-item {
        position: relative;

        margin-right: govuk-spacing(1);
        margin-bottom: 0;
        margin-left: 0;
        padding: govuk-spacing(2) govuk-spacing(4);

        float: left;
        background-color: govuk-colour('light-grey');
        text-align: center;

        &::before {
          content: none;
        }
      }

      .govuk-tabs__list-item--selected {
        $border-width: 1px;

        position: relative;

        margin-top: govuk-spacing(-1);

        // Compensation for border (otherwise we get a shift)
        margin-bottom: -$border-width;
        padding-top: govuk-spacing(3) - $border-width;
        padding-right: govuk-spacing(4) - $border-width;
        padding-bottom: govuk-spacing(3) + $border-width;
        padding-left: govuk-spacing(4) - $border-width;

        border: $border-width solid $govuk-border-colour;
        border-bottom: 0;

        background-color: $govuk-body-background-colour;

        .govuk-tabs__tab {
          text-decoration: none;
        }
      }

      .govuk-tabs__tab {
        @include govuk-link-style-text;

        margin-bottom: 0;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      .govuk-tabs__panel {
        @include govuk-responsive-margin(0, 'bottom');
        padding: govuk-spacing(6) govuk-spacing(4);
        border: 1px solid $govuk-border-colour;
        border-top: 0;

        & > :last-child {
          margin-bottom: 0;
        }
      }

      .govuk-tabs__panel--hidden {
        display: none;
      }
    }
  }
}

/*# sourceMappingURL=_index.scss.map */
