@import '../error-message/index';
@import '../hint/index';
@import '../label/index';

@include govuk-exports('govuk/component/file-upload') {
  $component-padding: govuk-spacing(1);

  .govuk-file-upload {
    @include govuk-font($size: 19);
    @include govuk-text-colour;
    max-width: 100%;
    margin-left: -$component-padding;
    padding: $component-padding;

    // The default file upload button in Safari does not
    // support setting a custom font-size. Set `-webkit-appearance`
    // to `button` to drop out of the native appearance so the
    // font-size is set to 19px
    // https://bugs.webkit.org/show_bug.cgi?id=224746
    &::-webkit-file-upload-button {
      -webkit-appearance: button;
      color: inherit;
      font: inherit;
    }

    &:focus {
      outline: $govuk-focus-width solid $govuk-focus-colour;
      // Use `box-shadow` to add border instead of changing `border-width`
      // (which changes element size) and since `outline` is already used for the
      // yellow focus state.
      box-shadow: inset 0 0 0 4px $govuk-input-border-colour;
    }

    // Set "focus-within" to fix https://bugzilla.mozilla.org/show_bug.cgi?id=1430196
    // so that component receives focus in Firefox.
    // This can't be set together with `:focus` as all versions of IE fail
    // to recognise `focus-within` and don't set any styles from the block
    // when it's a selector.
    &:focus-within {
      outline: $govuk-focus-width solid $govuk-focus-colour;

      box-shadow: inset 0 0 0 4px $govuk-input-border-colour;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

/*# sourceMappingURL=_index.scss.map */
